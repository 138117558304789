import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import React, {useState} from 'react'
import Head from './components/Head';
import Footer from './components/Footer'
import Obra from './components/Obra'

const Home = () =>{return(<h1>home</h1>)}
const Tag = () =>{return(<h1>Tag</h1>)}
const Art = () =>{return(<h1>Artists</h1>)}


function App() {
  
  const randomm = (max) =>{
    //setRan(Math.floor(Math.random() * max))
    return Math.floor(Math.random() * max)
  }
  const [ran, setRan] = useState(randomm(1000000))
  const setRandom = () => {
    const r = randomm(1000000)
    setRan(r)
    return r
  }
  return (
    <div class="absolute inset-0 -z-10 h-full w-full items-center px-5 py-5 [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#bf0505_200%)] text-white">
    
    <BrowserRouter>
        <Head ran = {ran} setRandom={setRandom}/>
      <Routes>
        <Route path="/" element={<Home/>} index />
        <Route path="/tags" element={<Tag />} />
        <Route path="/artists" element={<Art />} />
        <Route path={`/:id`}  element={<Obra />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </div>
  );
}

export default App;
