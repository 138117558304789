import React from "react";
import {Link} from 'react-router-dom';

export default function Head({ran, setRandom}) {
    return(
        <header className="header content-end">
          <div class="container mx-auto">
        <nav className="flex dark:bg-gray-900/10 justify-between text-white">
        <div className="flex items-center">
        <svg className=" justify-center h-8 w-8 text-red-500"  viewBox="0 0 24 24"  width="24"  height="24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <path d="M8 14s1.5 2 4 2 4-2 4-2" />  <line x1="9" y1="9" x2="9.01" y2="9" />  <line x1="15" y1="9" x2="15.01" y2="9" /></svg>
        </div>
          <div className="flex justify-between">
          <div className="search-container">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto ">
          <input type="text" placeholder="Search..." className="mx-auto p-2 border border-gray-300 rounded search-input" />
          </div>
        </div>
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to="/" className="nav-link flex items-center space-x-3 rtl:space-x-reverse self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Home</Link>
        </div>
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/tags" className="nav-link flex items-center space-x-3 rtl:space-x-reverse self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Tags</Link>
        </div>
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to="/artists" className="nav-link flex items-center space-x-3 rtl:space-x-reverse self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Artists</Link>
          </div>
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link onClick={setRandom} to={`/${ran}`} className="nav-link flex items-center space-x-3 rtl:space-x-reverse self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Random</Link>
          </div>
          <div className="flex items-center">
            <button className="px-4 bg-red-700">Sing in</button>
            <button className="px-4 bg-black">Register</button>
          </div>
        </div>
        </nav>
        </div>
      </header>
    );
}