import React from "react";

export default function Footer() {
    
    return(
        <footer className="footer text-center" >
        <div className="left-section">
          <div className="social-media">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
          <div className="copyright">
            &copy; {new Date().getFullYear()} Tu Empresa. Todos los derechos reservados.
          </div>
        </div>
        <div className="right-section">
          <div className="contact">
            Contacto: <a href="mailto:contacto@tuempresa.com">contacto@tuempresa.com</a>
          </div>
          <div className="patreon">
            <a href="https://www.patreon.com/tuempresa" target="_blank" rel="noopener noreferrer">Apóyanos en Patreon</a>
          </div>
        </div>
      </footer>
    );
}